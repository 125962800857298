
import SimpleImageSlider from "react-simple-image-slider";

import gallery1 from '../../assets/gallery1.JPG';
import gallery2 from '../../assets/gallery2.JPG';
import gallery3 from '../../assets/gallery3.JPG';
import gallery4 from '../../assets/gallery4.JPG';
import gallery5 from '../../assets/gallery5.JPG';
import gallery6 from '../../assets/gallery6.JPG';
import gallery7 from '../../assets/gallery7.JPG';

const images = [
    { url: gallery1 },
    { url: gallery2 },
    { url: gallery3 },
    { url: gallery4 },
    { url: gallery5 },
    { url: gallery6 },
    { url: gallery7 },
  ];

function GalleryPage(){
    return (
        <div style={{'padding':'30px', 'display':'flex', 'flexDirection':'column','alignItems':'center'}}>
            <p style={{'padding':'20px', 'fontSize':'50px', 'fontWeight':'bold'}}>Gallery</p>
            <SimpleImageSlider
                width={896}
                height={504}
                images={images}
                showBullets={true}
                showNavs={true}
            />
        </div>
    );
}

export default GalleryPage;