import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles({
  card: {
    display: 'flex',
  },
  cardDetails: {
    flex: 1,
  },
  cardMedia: {
    width: 300,
    height: 500
  },
});

export default function FeaturedPost(props) {
  const classes = useStyles();
  const { post } = props;

  return (
    <Grid item xs={12} sm={12} md={7} lg={6} >
        <Card className={classes.card} style={{background:'rgb(168,193,188)'}}>
          <div className={classes.cardDetails}>
            <CardContent style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
              <Hidden xsUp={true}>
                  <CardMedia style={{}}className={classes.cardMedia} image={post.image}/>
              </Hidden>
              <Typography component="h2" variant="h5" style={{fontWeight:'bold'}}>
                {post.title}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                {post.date}
              </Typography>
              <Typography variant="subtitle1" paragraph style={{marginTop:'20px'}}>
                {post.description}
              </Typography>
            </CardContent>
          </div>
          <Hidden xsDown>
            <CardMedia className={classes.cardMedia} image={post.image} title={post.image} />
          </Hidden>
        </Card>
    </Grid>
  );
}

FeaturedPost.propTypes = {
  post: PropTypes.object,
};