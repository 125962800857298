
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';

import {TESTIMONIAL_BANNER_GRADIENT_DIRECTION,
        TESTIMONIAL_BANNER_START_COLOR,
        TESTIMONIAL_BANNER_END_COLOR,
        MAJOR_FONT} from '../../constants';
import WindowDimension from '../../components/WindowDimension';

import Grid from '@material-ui/core/Grid';

import im1 from '../../assets/principal.JPG';
import './style.scss';

const WelcomeView = ({testimonials}) =>{

  const screen = WindowDimension();

  return (
    <div> 
    <CBanner>
      <div className='welcome-school'>
        <Grid container alignItems='center'>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <img src={im1}/>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className='welcome-content'>
              <p className='welcome-title'>Welcome to</p>
              <p className='welcome-school-name'>Adinath School</p>
              <p className='welcome-details'>
              We are extremely happy and excited to welcome the students to the community based school located at ward number 6 Chobhar, Kirtipur Municipality. Our goal is to support all kinds of students academically, socially and culturally. We are always ready to provide our best effort to make our students professionally sound to compete in the competitive world in which they live and work.<br/>
	            We are proud of our students because they strive for excellence and recognize that this can be exhibited in many different forms. Our academic results speak for themselves and are the product of an emphasis on continual self-improvement. Students are encouraged to explore, follow their curiosity and be creative in their thinking.<br/>
	            Please enjoy finding out more about our school through our website. For more information visit our school, see and feel what the reality is.
              </p>
            </div>

          </Grid>
        </Grid>
      </div>
    </CBanner>
      </div>
  );
}


export default WelcomeView;


const CBanner = styled.div`
  /* display:flex; */
  /* flex-direction: column; */
  /* margin-top:-58px; */
  /* justify-content: center; */
  text-align: center;
  /* background: linear-gradient(to ${TESTIMONIAL_BANNER_GRADIENT_DIRECTION}, ${TESTIMONIAL_BANNER_START_COLOR}, ${TESTIMONIAL_BANNER_END_COLOR}); */
  /* background: 'white'; */
  /* background: linear-gradient(to ${TESTIMONIAL_BANNER_GRADIENT_DIRECTION}, ${TESTIMONIAL_BANNER_START_COLOR}, ${TESTIMONIAL_BANNER_END_COLOR}); */
  margin-bottom:50px;
  margin-top:50px;
`;

const CTypography = styled(Typography)`
  &&&{
    margin-top:40px;
    margin-bottom:40px;
    font-size: ${props=>props.width>1280?100:(props.width>900?80:40)}px;
    color: red;
    font-family: ${MAJOR_FONT};
    font-weight: 900;
    /* text-shadow: 3px 3px 3px rgb(100,100,100); */
  }
`;

const C2Typography = styled(Typography)`
  &&&{
    /* margin-top:40px; */
    /* margin-bottom:40px; */
    font-size: ${props=>props.width>1280?60:(props.width>900?50:40)}px;
    color: black;
    font-family: ${MAJOR_FONT};
    font-weight: 500;
    /* text-shadow: 3px 3px 3px rgb(100,100,100); */
  }
`;

const C3Typography = styled(Typography)`
  &&&{
    /* margin-top:40px; */
    /* margin-bottom:40px; */
    font-size: ${props=>props.width>1280?100:(props.width>900?80:40)}px;
    color: black;
    font-family: ${MAJOR_FONT};
    font-weight: 900;
    /* text-shadow: 3px 3px 3px rgb(100,100,100); */
  }
`;