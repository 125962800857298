import {useState,useEffect} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import HomeIcon from '@material-ui/icons/Home';

import {withRouter} from 'react-router-dom';
import {useHistory} from 'react-router-dom';
import InfoIcon from '@material-ui/icons/Info';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import PeopleIcon from '@material-ui/icons/People';
import TimelineIcon from '@material-ui/icons/Timeline';
import WorkIcon from '@material-ui/icons/Work';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';

import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Paper from '@material-ui/core/Paper';
import {MAJOR_FONT, APPBAR_BG, SUBSIDING_FONT} from '../../constants';
import { makeStyles } from '@material-ui/core';

import './style.scss';

const MenuItemOptions = [
  {
    title: 'Home',
    icon: <HomeIcon/>,
    path: '/',
  },
  {
    title: 'Academics',
    submenu:[
      {
        title: 'Mission',
        path: '/mission'
      },
      {
        title: 'Vision',
        path: '/vision'
      },
      {
        title: 'Pedagogy',
        path: '/pedagogy'
      },
      {
        title: 'Schedule a meet',
        path: '/schedule-a-meet'
      }
    ]
  },
  {
    title: 'Communities',
    icon: null,
    submenu: [
      {
        title: 'Faculty and Staff',
        icon: <WorkIcon/>,
        path: '/faculty',
      },
      {
        title: 'Students and Families',
        icon: <PeopleIcon/>,
        path: '/about',
      },
      {
        title: 'Alumini',
        path: '/alumini',
        icon: <EmojiPeopleIcon/>
      },
     
    ]
  },
  {
    title: 'Blogs',
    path: '/blogs'
  },
  {
    title: 'About Us',
    icon: <InfoIcon/>,
    submenu:[
      {
        title: 'Gallery',
        icon: <TimelineIcon/>,
        path: '/gallery'
      },
      {
        title: 'Our Story',
        icon: <TimelineIcon/>,
        path: '/ourstory'
      },
      {
        title: 'Contact Us',
        icon: <ContactPhoneIcon/>,
        path: '/contactus',
      },
      {
        title: 'FAQ',
        icon: <HomeIcon/>,
        path: '/faq',
      }
    ]
  },

];

const useStyles = makeStyles({
  paper:{
    background:"rgb(168,193,188)",
  },
  subpaper:{
    background: "rgb(188,213,208)",
    paddingLeft: '50px'
  }
})

const AppBarResponsive = ({logo}) => {
    const history = useHistory();
    const classes = useStyles();
    
    const [subMenuBoolean,setSubMenuBoolen] = useState(Array(MenuItemOptions.length).fill(false));

    const [drawerActivate, setDrawerActivate] = useState(false);
    const [drawer, setDrawer] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [subMenuIndex, setSubMenuIndex] = useState(0);

    useEffect(()=>{
      if(window.innerWidth<=1000)
        setDrawerActivate(true);
      else
        setDrawerActivate(false);
    },[])

    useEffect(()=>{
      const handleResize = () =>{
        if(window.innerWidth <=1000){
          setDrawerActivate(true);
        }
        else{
          setDrawerActivate(false);
          setDrawer(false);
        }
        setAnchorEl(null);
      }
      window.addEventListener('resize',handleResize);

      return ()=>{
        window.removeEventListener('resize',handleResize);
      }
    })

    const popDrawerMenu = (isPopType,index) =>{
      if(isPopType){
        const clone = [...subMenuBoolean];
        clone[index] = !clone[index];
        setSubMenuBoolen(clone);
      }else{ //run some function from here
        history.push(MenuItemOptions[index].path);
        setDrawer(false);
      }
    }

    const [tabhighlighter,setTabhighlighter] = useState(0);

    const handleDropMenuOpen = (index,subs)=>(e) =>{
      if(MenuItemOptions[index].submenu ===undefined){
        history.push(MenuItemOptions[index].path);
        setTabhighlighter(index-subs)
      }
      else{
        setSubMenuIndex(index);
        setAnchorEl(e.currentTarget);
      }
    }

    const handleDropMenuHover = (index,subs) =>(e)=>{

     if(matchMedia('(pointer:fine)').matches){
        if(MenuItemOptions[index].submenu!==undefined){
          setSubMenuIndex(index);
          setAnchorEl(e.currentTarget);
        }
     }
    }

    const handleDropMenuHoverEnd = (index) =>{
        setAnchorEl(null);
    }

    const handleDropMenuClose = () =>{
      setAnchorEl(null);
    }

    const handleDropMenuItemClose = (index) =>{
      history.push(MenuItemOptions[subMenuIndex].submenu[index].path);
      handleDropMenuClose();
      setTabhighlighter(subMenuIndex);
    }

    const handleDrawerMenuClick = (index,subindex) =>{
      setDrawer(false);
      history.push(MenuItemOptions[index].submenu[subindex].path);
    }

    var subs = 0;

    return(
      <div>
        <CAppBar position='fixed'>
          <Toolbar>
            <div style={{display:'flex', cursor:'pointer', justifyContent:'center', alignItems:'center'}}
                        onClick={()=>history.push('/')} 
                        >
              <CustomLogo variant="headline" src={logo} height={100} />

              <Typography style={{fontSize:'18px', 
                                  color:'rgb(50,50,50)', 
                                  marginLeft:'30px',
                                  fontWeight:'bold',
                                  fontFamily:`Open Sans`,
                                  textAlign:'left',
                                  // lineHeight:'25px'
                                  }}>
                                  
                ADINATH <br/> 
                <span style={{fontSize:'14px', fontWeight:500, fontFamily:'Open Sans'}}>
                  CHOBAR
                </span>
              </Typography>

            </div>
            <div style={{flex:1}}/>
              {
                drawerActivate && 
                <div >
                  <IconButton 
                    onClick={()=>setDrawer(true)}
                    >
                    <MenuIcon style={{color:'black', fontSize:'35px'}}/>
                  </IconButton>
                </div>
              }

                    {!drawerActivate && 
                      // <Paper square style={{boxShadow:'none'}}>
                      //     <Tabs
                      //       value={tabhighlighter}
                      //       >
                      //   {
                      //     MenuItemOptions.map((item,index)=>{
                      //         if(!item.title) subs++;
                      //         return(item.title &&  
                      //             <Tab key={index} id={index} 
                      //                  label={item.title} 
                      //                   style={{fontSize:'20px', fontWeight:'bold',fontFamily:'Open Sans'}} 
                      //             onClick={handleDropMenuOpen(index,subs)}
                      //             />
                      //         );
                      //     })
                      //   }
                      //     </Tabs>
                      //   </Paper>
                      <div className='appbar-menu'>
                        {
                          MenuItemOptions.map((item,index)=>{
                            // console.log(item.title);
                            if(!item.title) subs++;
                            return (item.title && <span key={index} id={index}
                                                  onClick={handleDropMenuOpen(index,subs)}>
                                                    {item.title}
                                                  </span>);
                        })
                      }
                      </div>
                    }
          </Toolbar>
        </CAppBar>
        
        {
          MenuItemOptions[subMenuIndex].submenu && 
          <Menu style={{marginTop:'75px'}} id='menu' 
                onClose={handleDropMenuClose} 
                anchorEl={anchorEl} 
                open={Boolean(anchorEl)}
                // MenuListProps={{onMouseLeave:()=>handleDropMenuHoverEnd(subMenuIndex)}}
                >
          {
            MenuItemOptions[subMenuIndex].submenu.map((item,index)=>{
              return (
                <div key={index} id={index}>
                  <MenuItem onClick={()=>handleDropMenuItemClose(index)} 
                            style={{minWidth:'250px'}}>
                    <span>{item.icon}</span>
                    <Typography style={{marginLeft:'40px', fontFamily:'Open Sans'}}>
                      {item.title}
                    </Typography>
                  </MenuItem>
                </div>
              );
            })
          }
          </Menu>
        }

        <Drawer classes={{paper: classes.paper}} anchor='right' open={drawer} onClose={() => {setDrawer(false)}}>
          <List style={{ width: 250}}>
            {
              MenuItemOptions.map((item,index)=>{
                return (
                  <div key={index} id={index}>
                  {
                  item.title?
                    <>
                        <CListItem button onClick={()=>popDrawerMenu(item.submenu,index)}>
                          <ListItemIcon>{item.icon}</ListItemIcon>
                          <CListItemText primary={
                            <CMenuTypography>{item.title}</CMenuTypography>
                          }/>
                          {item.submenu && (subMenuBoolean[index] ? <ExpandLess/> : <ExpandMore/>)}
                        </CListItem>
                        {
                          item.submenu &&
                          <>
                            <Collapse in={subMenuBoolean[index]} timeout='auto' unmountOnExit>
                              <Divider/>
                              <List className={classes.subpaper}>
                                  {
                                    item.submenu.map((subitem,subindex)=>{
                                      return (
                                        <div key={index+subindex} id={index+subindex} >
                                          { subindex!==0 && <Divider/>}
                                          <CListItem button onClick={()=>handleDrawerMenuClick(index,subindex)}>
                                          <ListItemIcon>{subitem.icon}</ListItemIcon>
                                          <CListItemText primary={
                                            <Typography style={{fontSize:'15px',fontWeight:'300', fontFamily:'Open Sans'}}>
                                              {subitem.title}
                                            </Typography>
                                          }
                                          />
                                          </CListItem>
                                        </div>
                                      );
                                    })
                                  }
                              </List>
                              <Divider/>
                            </Collapse>
                          </>
                        }
                    </> :
                    <>
                       <Divider/>
                    </>
                  }
                  </div>
                );
              })
            }
          </List>
      </Drawer>

      </div>
    );
}

export default withRouter(AppBarResponsive);
// export default AppBarResponsive;

const CAppBar = styled(AppBar)`
  &&&{
    background: ${APPBAR_BG};
  }
`;

const CMenuTypography = styled(Typography)`
  &&&{
    font-family:'Open Sans';
    font-weight:700;
  }
`;

const CListItemText =styled(ListItemText)`
  &&&{
  }
`;

const CListItem = styled(ListItem)`
  &&&{
    cursor: pointer;
  }
  &:hover{
    background: rgba(128,128,128,0.5);
  }
`;

const CustomLogo = styled.img`
  margin: 10px 0;
  height: ${props => props.height-20}px;
  justify-content: "space-between";
  position: relative;
`;
