
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import WindowDimension from '../../components/WindowDimension';
import CoverPhoto from '../../components/CoverPhoto';

import pic from '../../assets/principal.jpg';
import { MAJOR_FONT } from '../../constants';

import './style.scss';

const MissionPhoto = () =>{
    const windowSize = WindowDimension();
    const maxHeight = 600;
    const minHeight = 400;
    return (
        <div>
            <BackColor width={windowSize.width} height={windowSize.height}>
                <Picture image={pic}>
                        <CDivision>
                            <CLabel>
                            DayaChand Regmi<br/>Principal, Dhawalagiri
                        </CLabel>
                    </CDivision>
                </Picture>
            </BackColor>
        </div>
    );
}

const MissionText = () =>{
    return (
        <div>
            <CMissionTitle>
                Our Mission
            </CMissionTitle>
        </div>
    );
}

const MissionPage = () =>{
    return (
        <div className='mission-container'>
            <Grid container spacing={2} style={{marginBottom:'100px'}} justify='center'>
                {/* <Grid item lg={6} md={6} sm={9} xs={11} >
                    <div className='mission-image'>
                        <img src={pic}/>
                        <div className='mission-label'>
                            <p>principal</p>
                        </div>
                    </div>
                </Grid> */}
                <Grid item lg={9} md={9} sm={9} xs={11}>
                    <div className='mission-content-container'>
                        <p className='mission-title'>विशिष्ट उद्देश्यहर</p>
                        <p className='mission-content'>
                        यस विद्यालयले आफ्नो दूरदृष्टिलाई साकार पार्न विशिष्ट उद्देश्यहरु देहाय बमोजिम छन् ।<br/>
                            <ul>
                                <li style={{'marginLeft':'50px'}}>समुदायको सहयोगमा विद्यालयको मापनयोग्य भौतिक सुधार गर्ने</li>
                                <li style={{'marginLeft':'50px'}}>विद्यालयको भर्नादर तथा पढाइ पूरा गर्नेको संख्यामा गुणात्मक सुधार गर्ने</li>
                                <li style={{'marginLeft':'50px'}}>कक्षा शिक्षण र सिकाइ उपलब्धि स्तरमा निरन्तर अभिवृद्धि हासिल गर्दै समाज उपयोगी कार्यक्रम तथा क्रियाकलाप मार्फत विद्यालय र समाजबीच सम्बन्ध सुदृढ गर्ने</li>
                                <li style={{'marginLeft':'50px'}}>स्थानीय अभिभावक, समाजसेवी, शिक्षाप्रेमी, विद्यार्थीहरु लगायत सरोकारवाला समुहहरुको संलग्नता र सकृयतमा अपनत्व वृद्धि गरी विद्यालयको सर्वाङ्गीण विकास गर्न दीर्घकालीन एवम् नियमित अभ्यास स्थापित गर्ने</li>
                                <li style={{'marginLeft':'50px'}}>स्थानीय तहको योजना निमार्ण परिमार्जन र कार्यान्वयन अनुगमन गर्ने, सीप तथा क्षमताको विकास गर्ने</li>
                                <li style={{'marginLeft':'50px'}}>स्थानीय तहमा स्रोतको पहिचान तथा परिचालन गरी सीमित स्रोतहरुबाट अधिकतम उपलब्धी हासिल गर्ने</li>
                                <li style={{'marginLeft':'50px'}}>विद्यालयका कार्यक्रमहरु योजनाबद्ध र व्यवस्थित तरिकाले प्राथमिकता निर्धारण गरी कार्यान्वयन गर्ने वानीको विकास गर्ने</li>
                                <li style={{'marginLeft':'50px'}}>विद्यालय समुदायका लागि हो र समुदायको सहयोग सबैका लागि हो भन्ने भावना जागृत गराउने</li>
                            </ul>
                        </p>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default MissionPage;


const BackColor = styled.div`
    position:relative;
    width: ${props=>(35*props.width/100)}px;
    height: ${props=>(50*props.height/100)}px;
    /* min-width: 350px; */
    /* min-height: 300px; */
    background-color: rgb(168,193,188);
    box-shadow: 10px 10px 10px 5px rgba(0,0,0,0.3);
`;

const Picture = styled.div`
    position:absolute;
    top:12%;
    left:10%;
    width: 80%;
    height: 100%;
    object-fit: cover;
    background-size:cover;
    /* background-image: url(${props=>props.image}); */
    box-shadow: 5px 5px 10px 5px rgba(0,0,0,0.2);
`;

const CDivision = styled.div`
    position:absolute;
    right:-10%;
    bottom:5%;
    width: 80%;
    padding-top:10px;
    padding-bottom:10px;
    /* text-align: center; */
    background-color:rgb(50,50,50);
    opacity: 0.8;
`;

const CLabel = styled.p`
    font-size:20px;
    font-family: ${MAJOR_FONT};
    /* font-weight: bold; */
    color:white;
    line-height: 40px;
    padding-left:30px;
`;

const CMissionTitle = styled.p`

`;