
import StoryView from '../../views/StoryView';

import './style.scss';

const StoryPage = () =>{
    return <>
            <div className='storypage-chronology'>
                <p>CHRONOLOGY</p>
            </div>
            <StoryView/>   
           </>;
}

export default StoryPage;